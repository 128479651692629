.Amenities {
  width: 100%;
  background-image: url('../../../../../assets/home/images/amenities/backto.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #E9EDF0;

  .space-top {
    padding-top: 20px;
  }

  .title {
    color: #0E6784;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 24.2px */
    letter-spacing: -0.44px;
  }

  .description {
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 13.2px */
    letter-spacing: -0.2px;
    width: 266px;
    margin-top: 12px;
  }

  .card-item {
    min-width: 200px;
    min-height: 200px;
    width: auto;
    flex-shrink: 0;
    margin-top: 28px;
    background-color: #E0E9ED;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image-item {
    max-width: 120px;
    height: 80px;
    flex-shrink: 0;
    margin:auto;
  }

  .item-title {
    color: #282828;
    font-family: 'Avenir Black';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 25.3px */
    letter-spacing: -0.44px;
    text-align: center;
    margin-top: 10px;
  }

  .item-subtitle {
    color: #6F6F6F;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.24px;
    text-align: center;
    margin-top: 4px;
  }

  .lot-section {
    margin-top: 80px;
  }

  .land-image {
    width: 280px;
    height: 300px;
    border-radius: 5px;
  }

  .land-title {
    color: #024E82;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
  }

  .land-subtitle {
    color: #282828;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
  }
  .land-descr{
    font-family: Poppins;
    font-size: 32px;
    font-weight: 500;
    line-height: 57.2px;
    letter-spacing: -0.02em;
    color: #0E6784;
  }

  .bg-sea {
    background: linear-gradient(90deg, rgba(2, 78, 130, 0.15) 0%, rgba(0, 150, 179, 0.15) 100%), linear-gradient(0deg, rgba(13, 19, 31, 0.15) 0%, rgba(13, 19, 31, 0.15) 100%), url('../../../../../assets/home/images/amenities/sea-sm.jpg'), lightgray -41px -472.276px / 105.764% 216.253% no-repeat;
    /* background-size: cover; */
    height: 200px;
    margin-top: 80px;
  }

  .sea-title {
    color: #E9EDF0;
    text-align: center;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
  }

  .sea-subtitle {
    color: #E9EDF0;
    font-family: Poppins;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0.5rem;
  }

  .gallery-section {
    margin-top: 20px;
  }

  .gallery-title {
    color: #024E82;
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    margin-top: 1rem;
  }

  .gallery-subtitle {
    color: #0E6784;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    margin-top: 0.5rem;
    text-align: center;
  }
  .item-img{
    text-align: center;
    margin: auto;
    min-width: 100%;
  }
  .profits{
    background-image: url('../../../../../assets/home/images/amenities/backto.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #E9EDF0;
  }

  @media (min-width: 768px) {
    .title {
      color: #0E6784;
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
    }
  
    .description {
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 13.2px */
      letter-spacing: -0.2px;
      width: 266px;
      margin-top: 12px;
    }
  
    .card-item {
      width: auto;
      flex-shrink: 0;
      margin-top: 28px;
    }
  
    .image-item {
      max-width: 180px;
      height: auto;
      flex-shrink: 0;
      margin: auto;
    }
  
    .item-title {
      color: #282828;
      font-family: 'Avenir Black';
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 115%; /* 25.3px */
      letter-spacing: -0.44px;
      text-align: center;
      margin-top: 10px;
    }
  
    .item-subtitle {
      color: #6F6F6F;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      letter-spacing: -0.24px;
      text-align: center;
      margin-top: 4px;
    }
  
    .lot-section {
      margin-top: 80px;
    }
  
    .land-image {
      width: 280px;
      height: 300px;
      border-radius: 5px;
    }
  
    .land-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
    }
  
    .land-subtitle {
      color: #282828;
      font-family: Inter;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
    }
  
    .bg-sea {
      background: linear-gradient(90deg, rgba(2, 78, 130, 0.15) 0%, rgba(0, 150, 179, 0.15) 100%), linear-gradient(0deg, rgba(13, 19, 31, 0.15) 0%, rgba(13, 19, 31, 0.15) 100%), url('../../../../../assets/home/images/amenities/sea-sm.jpg'), lightgray -41px -472.276px / 105.764% 216.253% no-repeat;
      /* background-size: cover; */
      height: 200px;
      margin-top: 80px;
    }
  
    .sea-title {
      color: #E9EDF0;
      text-align: center;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
    }
  
    .sea-subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 0.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 0.5rem;
    }
  
    .gallery-section {
      margin-top: 20px;
    }
  
    .gallery-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      margin-top: 1rem;
    }
  
    .gallery-subtitle {
      color: #0E6784;
      font-family: 'Avenir Black';
      font-size: 25px;
      font-style: normal;
      font-weight: 800;
      margin-top: 0.5rem;
    }
    .item-img{
      text-align: center;
      margin: auto;
      min-width: 100%;
    }
    .profits{
      background-image: url('../../../../../assets/home/images/amenities/backto.png');
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #E9EDF0;

    }
  }

  @media (min-width: 1024px) {
    .space-top {
      padding-top: 20px;
    }

    .title {
      color: #0E6784;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
      margin-top: 79px;
      text-align: center;
      margin-left: 43px;
    }

    .description {
      color: #282828;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 18.48px */
      letter-spacing: -0.28px;
      width: 213px;
      margin-top: 18px;
      text-align: left;
      margin-left: 43px;
    }

    .card-item {
      max-width: 252px;
      max-height: 250px;
      width: auto;
      flex-shrink: 0;
      margin-top: 28px;
    }
  
    .image-item {
      max-width: 80px;
      height: auto;
      flex-shrink: 0;
      margin:auto;
    }
  
    .item-title {
      color: #282828;
      font-family: 'Avenir Black';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%; /* 25.3px */
      letter-spacing: -0.44px;
      text-align: center;
      margin-top: 10px;
    }

    .item-subtitle {
      color: #6F6F6F;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      letter-spacing: -0.24px;
      text-align: center;
      margin-top: 4px;
    }

    .lot-section {
      margin-top: 160px;
    }

    .land-image {
      width: 800px;
      border-radius: 5px;
    }
  
    .item-subtitle {
      color: #6F6F6F;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      letter-spacing: -0.24px;
      text-align: center;
    }

    .land-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
    }
  
    .land-subtitle {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
    }
    .land-descr{
      font-family: Poppins;
      font-size: 52px;
      font-weight: 500;
      line-height: 57.2px;
      letter-spacing: -0.02em;
      color: #0E6784;
    }
    .bg-sea {
      background: linear-gradient(90deg, rgba(2, 78, 130, 0.15) 0%, rgba(0, 150, 179, 0.15) 100%), linear-gradient(0deg, rgba(13, 19, 31, 0.15) 0%, rgba(13, 19, 31, 0.15) 100%), url('../../../../../assets/home/images/amenities/sea.jpeg'), lightgray -41px -472.276px / 105.764% 216.253% no-repeat;
      /* background-size: cover; */
      height: 500px;
      margin-top: 140px;
    }
  
    .sea-title {
      color: #E9EDF0;
      text-align: center;
      font-family: Poppins;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 48.4px */
      letter-spacing: -0.88px;
    }
  
    .sea-subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.36px;
      margin-top: 2rem;
    }

    .gallery-section {
      margin-top: 20px;
    }

    .gallery-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
    }
  
    .gallery-subtitle {
      color: #0E6784;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      margin-top: 1.5rem;
      text-align: center;
    }
  }

  @media (min-width: 1440px) {
    .space-top {
      padding-top: 20px;
    }

    .title {
      color: #0E6784;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
      margin-top: 29px;
      text-align: center;
      margin-left: 63px;
    }

    .description {
      color: #282828;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 18.48px */
      letter-spacing: -0.28px;
      text-align: left;
      margin-left: 63px;
      width: 343px;
    }

    .card-item {
      width: 272px;
      height: 270px;
      flex-shrink: 0;
      margin-top: 28px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .image-item {
      max-width: 120px;
      height: 105px;
      flex-shrink: 0;
      margin:auto;
    }
  
    .item-title {
      color: #282828;
      font-family: 'Avenir Black';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%; /* 25.3px */
      letter-spacing: -0.44px;
      text-align: center;
      margin-top: 10px;
    }

    .item-subtitle {
      color: #6F6F6F;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */
      letter-spacing: -0.24px;
      text-align: center;
      margin-top: 4px;
    }

    .lot-section {
      margin-top: 160px;
    }

    .gallery-section {
      margin-top: 20px;
    }
  }
}