.hero-text {
    color: #4E4E4E;
    font-family: 'Poppins';
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.soon-text {
    color: #666;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 132%; /* 23.76px */
    letter-spacing: -0.36px;
}