.ProtectedLayout {
  width: 100vw;
  height: 100vh;
  background-color: #E9EDF0;
  .navbar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: fixed;
    z-index: 100;
    padding-top: 0.5rem;
  }
  .lang-icon {
    color: black;
    font-size: 1rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  .nav {
    float: left;
  }
  .nav ul {
    list-style: none;
    overflow: hidden; 
  }
  
  .nav ul li {
    float: left;
    font-family: Arial, sans-serif;
    font-size: 1rem;
  }
  .nav ul li a {
    display: block;
    color: #fff;
    text-decoration: none;
    padding-right: 1.5rem;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
  }
  .content {
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: #E9EDF0;
  }
  
  .head-nav {
    margin-top: 2rem;
    font-family: Poppins;
    font-size: 13.908px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    li {
      padding-left: 20px !important;
      margin-bottom: 10px;
      &:hover{
          &:before {
              background-color: #1a1a1a;
          }
          a {
            color: #0096B3;
          }
      }
      &:before{
          position: absolute;
          left: -0.925rem;
          top: 0;
          width: 0.925rem;
          height: 100%;
          content: "";
          background: #ED1C5B;
          z-index: 1;
          transition: background 400ms;
      }
    }
    ul{
        margin-top: 0.925rem * 2;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
        padding: 0;
        list-style: none;
    }
    li{
        position: relative;
        clear: both;
        width: 100%;
        padding: 0;
        transition: background 400ms;
        a {
            display: flex;
            padding-top: 1.5vh;
            padding-bottom: 1.5vh;
        }
    }
    a {
      align-items: center;
      width: 100%;
      color: #fefefe;
      text-decoration: none;
      transition: color 400ms;
      span {
          position: relative;
          display: block;
          z-index: 0;
          font-family: Roboto;
          text-indent: -20em;
          white-space: nowrap;
          transition: text-indent 400ms ease-in-out;
      }
    }
    /* svg{
        position: relative;
        max-width: 80px;
        max-height: 30px;
        z-index: 1;
        fill: #fefefe;
        background: #ED1C5B;
        transition: 400ms;
    } */
    @media screen and (max: 768px){
        ul{
            margin-top: 0.925rem/4;
        }
        svg{
            max-width: 20px;
        }
    }
  }
  .main-head{
    position: fixed;
    bottom: 0;
    height: 100%;
    z-index: 1;
    background: rgba(255,255,255,1);
    /* transition: width 400ms; */
    border-right: 1px solid #c0dcff;
    .title {
      color: white;
      padding-left: 1rem;
    }
    &:hover{
        /* width: 236px; */
        & + .wrap-all-the-things{
            transform: translateX(136px);
            max-width: 100%;
            opacity: 0.4;
        }
        .head-nav{
            /* li{
                @for $i from 1 through 4 {
                    &:nth-of-type(#{$i}){
                        span{
                            transition-delay: 100ms * $i;
                        }
                    }
                }
            } */
            span{
                text-indent: 0;
            }
        }
    }
    @media screen and (max: 768px){
        width: 70px;
        padding: 0 0.925rem;
        .head-nav{
            padding-left: 7px;
        }
        &:hover{
            .head-nav{
                span{
                    text-indent: 1em;
                }
            }
        }
    }
  }
  .wrap-all-the-things {
    min-height: 100%;
    height: 100%;
    margin-top: 0;
    transition: transform 400ms, opacity 400ms;
  }

}

.title {
  font-size: 24.84px;
  line-height: 37.26px;
  font-weight: 300;
}

.btn-text {
  color: white;
  font-weight: 300;
  font-size: 11.05px;
  line-height: 16.58px;
}

table thead {
  color: #4C4C4C;

  font-family: 'Inter';
  font-size: 13.146px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: 885.37px;
  height: 59.75px;
  flex-shrink: 0;
}

input:focus-visible {
  /* border-radius: 0px !important; */
  outline: #FFF;
}

.link {
  color: #058CBF;

  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}
