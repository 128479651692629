.select-text {
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.dropdown-indicator {
  background-image: linear-gradient(90deg, #058cbf 0%, #41cfe3 100.01%);
  width: 58px;
  height: 28.5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-indicator img {
  width: 16px;
  height: 20px;
}

.old-dropdown-indicator {
  background-image: linear-gradient(90deg, #058cbf 0%, #41cfe3 100.01%);
  width: 44px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.old-dropdown-indicator img {
  width: 20px;
  height: 20px;
}
