.title {
    color: #000;
    font-family: 'Poppins';
    font-size: 24.84px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.242px;
}

.big-label {
    color: #000;
    font-family: 'Poppins';
    font-size: 24.84px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.242px;
}

.title-white {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.05em;
    text-align: left;

    color: #FFF;
    position: absolute;
    top: 20%;
    left: 35%;
    transform: translateX(-50%);
}

/* .modal-container {
    position: relative;
    display: inline-block;
} */

.trigger {
cursor: pointer;
}

.modal-details-container {
    position: absolute;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    right: 100px;
    left: auto !important;
}