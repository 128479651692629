.label-details {
    color: #024E82 !important;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.24px;
}

.text {
    color: #666;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 11.88px */
    letter-spacing: -0.18px;

    input {
        background-color: transparent;
        border: none;
    }
}

.card-title {
    color: #2C2F31;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}