.circle-chart {
    min-width: 343px;
    height: auto;
    border-radius: 12px;
    background: #FFF;
    padding: 10px;
  
    .title {
      color: #4C4C4C;
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0px;
      padding: 5px 10px;
    }
  }
  .circle-graph {
    width: 400px !important;
    height: 300px;
  }