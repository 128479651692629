.Buy {
  width: 100%;
  background-color: #E9EDF0;

  .title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 25.3px */
    letter-spacing: -0.46px;
    width: 280px;
  }

  .subtitle {
    color: #4C4C4C;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.24px;
    width: 280px;
    margin-top: 10px;
  }

  .carousel {
    padding-top: 24px;
  }

  .image {
    width: 182px;
    height: 142px;
  }

  .pre-sale-title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 57px;
    font-style: normal;
    font-weight: 400;
    line-height: 104%; /* 59.28px */
    letter-spacing: -1.14px;
    width: 300px;
    margin-top: 80px;
  }

  .pre-sale-description {
    margin-top: 22px;
    width: auto;
    height: 139px;
    color: #024E82;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 46.2px */
    letter-spacing: -0.84px;
  }

  .amount-one {
    color: #024E82;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.96px;
  }

  .amount-two {
    color: rgba(2, 78, 130, 0.17);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.84px;
  }

  .currency {
    color: #024E82;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.32px;
  }

  .currency-two {
    color: rgba(2, 78, 130, 0.17);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.32px;
  }

  .m2 {
    font-size: 10px;
  }

  .line {
    position: absolute;
    width: 110px;
    height: 0px;
    flex-shrink: 0;
    border: 2px solid #04243A;
    margin-top: 60px;
    margin-left: 0px;
  }

  .zihua {
    position: absolute;
    width: 347px;
    height: 147px;
    margin-top: -70px;
    margin-left: 0px;
  }

  .active {
    width: 7.887px;
    height: 7.887px;
    border-radius: 7.887px;
    background: #024E82;
  }

  .inactive {
    width: 7.887px;
    height: 7.887px;
    border-radius: 7.887px;
    background-color: #C3D2D4;
  }


  @media (min-width: 768px) {
    .image {
      width: 332px;
      height: 292px;
    }
  }

  @media (min-width: 1024px) {
    .title {
      color: #024E82;
      text-align: center;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      width: 937px;
    }

    .subtitle {
      color: #4C4C4C;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
      width: 692px;
      margin-top: 10px;
    }

    .gallery {
      margin-top: 68px;
    }

    .first-row-image {
      width: 310px;
      height: 250px;
      border-radius: 6px;
    }

    .second-row-image {
      width: 310px;
      height: 175px;
      border-radius: 6px;
      /* margin-left: 15px; */
    }

    .third-row-image {
      width: 310px;
      height: 250px;
      border-radius: 6px;
      /* margin-left: 15px; */
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 137.28px */
      letter-spacing: -2.64px;
      margin-top: 116px;
      width: 425px;
      /* margin-left: 165px; */
      text-align: left;
    }

    .pre-sale-description {
      color: #024E82;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 46.2px */
      letter-spacing: -0.84px;
      width: 547px;
      margin-left: 257px;
      margin-top: 21px;
    }

    .amount-one {
      color: #024E82;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.96px;
    }

    .amount-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.84px;
    }

    .currency {
      color: #024E82;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .currency-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .m2 {
      font-size: 20px;
    }

    .line {
      position: absolute;
      width: 190px;
      height: 0px;
      flex-shrink: 0;
      border: 4px solid #04243A;
      margin-top: 330px;
      margin-left: 560px;
    }

    .zihua {
      position: absolute;
      width: 347px;
      height: 157px;
      margin-top: 250px;
      margin-left: 230px;
    }
  }

  @media (min-width: 1366px) {
    .title {
      color: #024E82;
      text-align: center;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      width: 937px;
    }

    .subtitle {
      color: #4C4C4C;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
      width: 692px;
      margin-top: 10px;
    }

    .gallery {
      margin-top: 68px;
    }

    .first-row-image {
      width: 410px;
      height: 308px;
      border-radius: 6px;
    }

    .second-row-image {
      width: 410px;
      height: 209px;
      border-radius: 6px;
      /* margin-left: 15px; */
    }

    .third-row-image {
      width: 410px;
      height: 308px;
      border-radius: 6px;
      /* margin-left: 30px; */
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 137.28px */
      letter-spacing: -2.64px;
      margin-top: 116px;
      width: 625px;
      text-align: left;
    }

    .pre-sale-description {
      color: #024E82;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 46.2px */
      letter-spacing: -0.84px;
      width: 547px;
      margin-left: 297px;
      margin-top: 21px;
    }

    .amount-one {
      color: #024E82;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.96px;
    }

    .amount-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.84px;
    }

    .currency {
      color: #024E82;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .currency-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .m2 {
      font-size: 20px;
    }

    .line {
      position: absolute;
      width: 207px;
      height: 0px;
      flex-shrink: 0;
      border: 4px solid #04243A;
      margin-top: 330px;
      margin-left: 600px;
    }

    .zihua {
      position: absolute;
      width: 347px;
      height: 157px;
      margin-top: 270px;
      margin-left: 270px;
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #024E82;
      text-align: center;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      width: 937px;
    }

    .subtitle {
      color: #4C4C4C;
      text-align: center;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
      width: 692px;
      margin-top: 10px;
    }

    .gallery {
      margin-top: 68px;
    }

    .first-row-image {
      width: 439px;
      height: 348px;
      border-radius: 6px;
    }

    .second-row-image {
      width: 378px;
      height: 249px;
      border-radius: 6px;
      /* margin-left: 30px; */
    }

    .third-row-image {
      width: 439px;
      height: 348px;
      border-radius: 6px;
      /* margin-left: 30px; */
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 132px;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 137.28px */
      letter-spacing: -2.64px;
      margin-top: 216px;
      width: 705px;
      text-align: left;
    }

    .pre-sale-description {
      color: #024E82;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 46.2px */
      letter-spacing: -0.84px;
      width: 580px;
      margin-left: 450px;
      margin-top: 21px;
    }

    .amount-one {
      color: #024E82;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.96px;
    }

    .amount-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.84px;
    }

    .currency {
      color: #024E82;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .currency-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .m2 {
      font-size: 20px;
    }

    .line {
      position: absolute;
      width: 207px;
      height: 0px;
      flex-shrink: 0;
      border: 4px solid #04243A;
      margin-top: 530px;
      margin-left: 810px;
    }

    .zihua {
      position: absolute;
      width: 397px;
      height: 177px;
      margin-top: 450px;
      margin-left: 410px;
    }
  }

  @media (min-width: 1880px) {
    .title {
      color: #024E82;
      text-align: center;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      width: 937px;
    }

    .subtitle {
      color: #4C4C4C;
      text-align: center;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
      width: 692px;
      margin-top: 10px;
    }

    .gallery {
      margin-top: 68px;
    }

    .first-row-image {
      width: 439px;
      height: 348px;
      border-radius: 6px;
    }

    .second-row-image {
      width: 378px;
      height: 249px;
      border-radius: 6px;
      /* margin-left: 30px; */
    }

    .third-row-image {
      width: 439px;
      height: 348px;
      border-radius: 6px;
      /* margin-left: 30px; */
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 132px;
      font-style: normal;
      font-weight: 400;
      line-height: 104%; /* 137.28px */
      letter-spacing: -2.64px;
      margin-top: 216px;
      width: 705px;
      text-align: left;
    }

    .pre-sale-description {
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 46.2px */
      letter-spacing: -0.84px;
      width: auto;
      margin-left: 600px;
      margin-top: 21px;
    }

    .amount-one {
      color: #024E82;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.96px;
    }

    .amount-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.84px;
    }

    .currency {
      color: #024E82;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .currency-two {
      color: rgba(2, 78, 130, 0.17);
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.32px;
    }

    .m2 {
      font-size: 20px;
    }

    .line {
      position: absolute;
      width: 207px;
      height: 0px;
      flex-shrink: 0;
      border: 4px solid #04243A;
      margin-top: 540px;
      margin-left: 1000px;
    }

    .zihua {
      position: absolute;
      width: 397px;
      height: 177px;
      margin-top: 480px;
      margin-left: 550px;
    }
  }
}