.file-label {
    color: #7E7E7E;

    font-family: 'Poppins';
    font-size: 15.594px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.file-input {
    background-image: linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%);
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    color: #fff;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

input[type="file"] {
    display: none;
}

