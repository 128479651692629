.Footer {
  width: 100vw;
  height: auto;
  background: linear-gradient(90deg, #166876 0%, #0096B3 100.01%);


  .logo {
    width: 123px;
  }

  a {
    display: block;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  .link {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 19.2px */
  }

  @media (min-width: 1024px) {
    a {
      display: block;
      text-align: center;
      text-decoration: none;
      color: #FFF;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .link {
      color: #FFF;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 19.2px */
    }
  }

  @media (min-width: 1440px) {
    a {
      display: block;
      text-align: center;
      text-decoration: none;
      color: #FFF;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }

}