.DashboardSuperAdmin {
  width: 100%;

  .title {
    color: #1e1e1e;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .chart {
    width: 20rem;
    height: 178.32px;
    margin-top: 20px;
  }

  @media (min-width: 1024px) {
    .title {
      color: #1e1e1e;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-align: left;
    }
  }

  @media (min-width: 1140px) {
    .chart {
      width: 16rem;
    }
  }

  @media (min-width: 1200px) {
    .chart {
      width: 17rem;
    }
  }

  @media (min-width: 1304px) {
    .chart {
      width: 18.5rem;
    }
  }

  @media (min-width: 1390px) {
    .chart {
      width: 19rem;
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #1e1e1e;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-align: left;
    }
  }
}
.primary-btn-shadow {
  border-radius: 4px;
  -webkit-box-shadow: 2px 9px 15px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 2px 9px 15px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 2px 9px 15px 0px rgba(0, 0, 0, 0.12);
}

.shadow {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
}

.table-container {
  margin-top: 4rem;
}

.chart-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
