.RegisterPublic {
  width: 100%;
  height: 100vh;
  background-color: #F4F8F6;

  .form-title {
    color: #156531;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 49.14px */
  }

  .form-subtitle {
    color: #282828;
    text-align: center;
    font-family: 'Avenir Black';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 132%; /* 29.04px */
    letter-spacing: -0.44px;
  }

  label {
    color: #476953;
    font-family: 'Avenir Black';
    font-size: 15.594px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
  }

  input {
    background-color: #FFF;
    border: 1px solid #D1DCEB;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(136, 136, 136, 0.13));
    padding: 5px;
  }

  .bg-gradient-custom {
    background: linear-gradient(90deg, #438D5D 0%, #69D890 100%);
    filter: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.25));
  }

  @media (min-width: 768px) {
    .form-title {
      color: #156531;
      text-align: center;
      font-family: Poppins;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 49.14px */
    }

    .form-subtitle {
      color: #282828;
      text-align: center;
      font-family: 'Avenir Black';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%; /* 29.04px */
      letter-spacing: -0.44px;
    }
  }

  @media (min-width: 1024px) {
    .form-title {
      color: #156531;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 49.14px */
    }

    .form-subtitle {
      color: #282828;
      text-align: center;
      font-family: 'Avenir Black';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%; /* 29.04px */
      letter-spacing: -0.44px;
    }
  }

  @media (min-width: 1440px) {
    .form-title {
      color: #156531;
      text-align: center;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 49.14px */
    }

    .form-subtitle {
      color: #282828;
      text-align: center;
      font-family: 'Avenir Black';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 132%; /* 29.04px */
      letter-spacing: -0.44px;
    }
  }
}