.input-form {
 
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(136, 136, 136, 0.13);
}

.card-input {
    background: url('../../../assets/images/allowed-card.svg') no-repeat right center;
    background-origin: content-box;
    background-size: auto 100%;
    padding-right: 10px;
    box-sizing: border-box; 
}

.cvc-input {
    background: url('../../../assets/images/cvc.svg') no-repeat right center;
    background-origin: content-box;
    background-size: auto 100%;
    padding-right: 10px;
    box-sizing: border-box; 
}

.label-form {
    color: #5A5A5A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}