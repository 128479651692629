.divider {
    height: 4px;
    background: linear-gradient(90deg, rgba(63, 205, 226, 0.18) 0%, rgba(238, 253, 255, 0.18) 101.96%);;
}

.no-card {
    color: #5A5A5A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}