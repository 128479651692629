.UsersTable {
  width: 100%;
  .table-section {
    overflow-x: auto;
  }

  .card-info {
    background-color: #F8F9FB;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px 20px;
  }

  .field-title {
    color: #4C4C4C;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .field-value {
    color: #4C4C4C;
    text-align: right;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}