.Location {
  width: 100%;
  background-color: #E9EDF0;

  .title {
    color: #024E82;
    text-align: center;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 24px */
    letter-spacing: -0.48px;
  }

  .map {
    width: 301px;
    height: 193px;
    margin-top: 24px;
  }

  @media (min-width: 1024px) {

    .title {
      color: #024E82;
      font-family: 'Poppins';
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
      text-align: center;
    }

    .map {
      width: 687.458px;
      height: 444px;
      margin-top: 58px;
    }
  
  }

  @media (min-width: 1440px) {
    .space {
      padding-top: 160px;
    }

    .map {
      width: 687.458px;
      height: 444px;
      margin-top: 58px;
    }
  }
}