.LineChart {
  height: auto;
  width: 100%;
  border-radius: 12px;
  background: #FFF;
  border-radius: 12px;
  padding: 0px 0px 0px 0px;

  .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    padding: 5px 10px;
    font-size: 18px;
    color: #000000;
    padding-bottom: 20px;
  }
}