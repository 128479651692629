.secondary-btn {
    color: #5A5A5A;
    font-family: 'Poppins';
    font-size: 10.8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* text-transform: uppercase; #upper from taillwind*/
}

.secondary-disabled-text {
    color: #fff;
    font-family: 'Poppins';
    font-size: 10.8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* text-transform: uppercase; #upper from taillwind*/
}

.secondary-size {
    max-width: 187.34px;
    width: 187.34px;
    height: 26.04px;
    max-height: 26.04px;
}