html {
    color: #203459;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

h1 {
    color: #203459;
    font-family: 'Poppins';
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}