.SectionForm {
  width: 100%;

  .label {
    display: block;
  }

  .error-message {
    font-size: small;
    color: red;
    padding-top: 0.2rem;
  }
}