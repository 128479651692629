.DashboardClient {
    width: 100vw;
  }
  
  /* Styles for the custom select container */
  .custom-select {
    position: relative;
    display: inline-block;
  }
  
  /* Styles for the select box */
  .custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 200px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #D1DCEB;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Styles for the dropdown arrow */
  .custom-select::after {
    background-image: linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%);
    content: url('../../../../../../src/assets/images/row.svg');
    position: absolute;
    top: 50%;
    right: 1px;
    width: 40px;
    height: 37px;
    transform: translateY(-50%);
    text-align:center;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .gray-card {
    fill: #F1F3F4;
    background-color: #F1F3F4;;
  }