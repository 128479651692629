.DashboardSeller {
  /* width: 100%; */
  .title {
    color: #1E1E1E;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .subtitle {
    color: #4C4C4C;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }

  .chart {
    width: 20rem;
    height: 178.32px;
    margin-top: 20px;
  }

  @media (min-width: 1140px) {
    .chart {
      width: 16rem;
    }
  }

  @media (min-width: 1200px) {
    .chart {
      width: 17rem;
    }
  }

  @media (min-width: 1304px) {
    .chart {
      width: 18.5rem;
    }
  }

  @media (min-width: 1390px) {
    .chart {
      width: 19rem;
    }
  }
}