.NotificationList {
  width: 100%;

  .title {
    text-align: left;
    color: #1E1E1E;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .card-info {
    background-color: #F8F9FB;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px 20px;
  }
}