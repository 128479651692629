.sellers-table {
  width: 100%;
  .table-section {
    overflow-x: auto;
  }

  .card-info {
    background-color: #f8f9fb;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px 20px;
  }

  .field-title {
    color: #4c4c4c;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .field-value {
    color: #4c4c4c;
    text-align: right;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

.gradient-background {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(5, 140, 191, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(5, 140, 191, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(5, 140, 191, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#00d4ff",GradientType=1);
}

.header {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.shadow {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
}