.Header {
  width: auto;
  height: 100vh;
  background: linear-gradient(0deg, rgba(2, 78, 130, 0.18) 0%, rgba(2, 78, 130, 0.18) 100%), url("../../../../../assets/home/images/header.png"), lightgray -4.059px -94.644px / 118.409% 118.371% no-repeat;
  background-size: cover;
  
  .title {
    color: #E9EDF0;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 22px */
    letter-spacing: -0.44px;
  }

  .subtitle {
    color: #E9EDF0;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 13.2px */
    letter-spacing: -0.2px;
    margin-top: 10px;
    width: 266px;
  }

  .btn {
    fill: var(--Linear, linear-gradient(90deg, #058CBF 0%, #41CFE3 100%));
    filter: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.25));
  }

  .explore {
    margin-top: 22px;
  }
  .banner-logo{
    margin-top: 0vh;
  }

  .logo-content-width {
    width: 90%
  }

  @media (min-width: 768px) {
    .title {
      color: #E9EDF0;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 22px */
      letter-spacing: -0.44px;
    }
  
    .subtitle {
      color: #E9EDF0;
      text-align: center;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 13.2px */
      letter-spacing: -0.2px;
      margin-top: 10px;
      width: 266px;
    }
  
    .btn {
      fill: var(--Linear, linear-gradient(90deg, #058CBF 0%, #41CFE3 100%));
      filter: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.25));
    }
  
    .explore {
      margin-top: 22px;
    }
    .banner-logo{
      margin-top: 0vh;
    }
  
    .logo-content-width {
      width: 90%
    }
  }

  @media (min-width: 1024px) {
    width: auto;
    height: 100vh;

    .title {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 48px */
      letter-spacing: -0.96px;
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      margin-left: 0;
    }

    .subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 35rem;
      text-align: left;
      margin-top: 10px;
      margin-left: 5rem;
    }

    .explore {
      margin-top: 18px;
      margin-left: auto;
      margin-right: auto;
    }
    .banner-logo{
      margin-top: 40vh;
    }
  }

  @media (min-width: 1366px) {
    width: auto;
    height: 100vh;

    .title {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 48px */
      letter-spacing: -0.96px;
      width: 100%;
      text-align: center;
    }

    .subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 35rem;
      text-align: left;
      margin-top: 10px;
      margin-left: 5rem;
    }

    .explore {
      margin-top: 18px;
      margin-left: auto;
      margin-right: auto;
    }
    .banner-logo{
      margin-top: 30vh;
    }
  }

  @media (min-width: 1440px) {
    width: auto;
    height: 100vh;
    .title {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 48px */
      letter-spacing: -0.96px;
      width: 100%;
      text-align: center;
      margin-top: 2.5rem;
      margin-left: 0rem;
    }

    .subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 35rem;
      text-align: left;
      margin-top: 14px;
      margin-left: 7rem;
    }

    .explore {
      margin-top: 28px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 1880px) {
    width: auto;
    height: 100vh;
    .title {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 48px */
      letter-spacing: -0.96px;
      width: 100%;
      text-align: center;
      margin-top: 3rem;
      margin-left: 0;
    }

    .subtitle {
      color: #E9EDF0;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 35rem;
      text-align: left;
      text-align: left;
      margin-top: 14px;
      margin-left: 7rem;
    }

    .explore {
      margin-top: 28px;
      margin-left: auto;
      margin-right: auto;
    }
    .banner-logo{
      margin-top: 40vh;
    }
  }
}