.LotLand {
  width: 100%;
}
.container {
  width: 100%;
  padding: 30px;
  padding-right: 50px;
}
.text-black-50 {
  color: #4C4C4C;
}