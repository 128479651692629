.userTitle {
    color: #1E1E1E;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.progress-label {
    color: #5A5A5A;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 115%; /* 13.8px */
}

table thead tr td {
    color: #4C4C4C;
    font-family: 'Poppins';
    font-size: 13.146px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.table-payments{
    border-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.25));
}

.text-pending {
    color: #A5BEF0;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-approved {
    color: #5EC394;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-rejected {
    color: #F45D85;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.text-gray {
    color: #A2AAAB;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.text-link {
    color: #058CBF;
    text-decoration: underline;
}