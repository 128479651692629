.Phases {
  width: 100%;
  background-color: #E9EDF0;

  .title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
  }

  .subtitle {
    margin-top: 6px;
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 13.2px */
    letter-spacing: -0.2px;
    width: 146px;
  }

  .pre-sale-image {
    width: 300px;
    height: 186px;
    margin-top: 22px;
  }

  .pre-sale-title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 19.8px */
    letter-spacing: -0.36px;
    text-align: center;
    margin-top: 10px;
  }

  .pre-sale-description {
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 11px */
    letter-spacing: -0.2px;
    width: 211.875px;
    margin-top: 4px;
  }

  .urbanization {
    margin-top: 42px;
  }

  .urbanization-image {
    width: 300px;
    height: 186px;
  }

  .urbanization-title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 19.8px */
    letter-spacing: -0.36px;
    margin-top: 10px;
  }

  .urbanization-description {
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 11px */
    letter-spacing: -0.2px;
    width: 211.875px;
    margin-top: 4px;
  }

  .development-description {
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 11px */
    letter-spacing: -0.2px;
    width: 211.875px;
    margin-top: 4px;
  }

  .delivery {
    margin-top: 31px;
  }

  .delivery-title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 19.8px */
    letter-spacing: -0.36px;
    margin-top: 10px;
  }

  @media (min-width: 1024px) {
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 110px */
      letter-spacing: -2px;
      text-align: center;
    }

    .subtitle {
      margin-top: 6px;
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 100%;
    }

    .pre-sale {
      margin-top: 54px;
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 155px;
      text-align: left;
      width: 408px;
    }

    .pre-sale-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 408px;
      margin-top: 8px;
      text-align: left;
    }

    .pre-sale-image {
      width: 606px;
      height: 404px;
    }

    .urbanization {
      margin-top: 80px;
    }

    .urbanization-image {
      width: 606px;
      height: 404px;
    }

    .urbanization-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 155px;
      text-align: left;
    }

    .urbanization-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 408px;
      margin-top: 8px;
      text-align: left;
    }

    .development {
      margin-top: 80px;
    }

    .development-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 408px;
      text-align: left;
    }

    .delivery {
      margin-top: 80px;
    }

    .delivery-title {
      width: 408px;
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 155px;
      text-align: left;
    }
  }

  @media (min-width: 1366px) {
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 80px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 110px */
      letter-spacing: -2px;
      text-align: center;
    }

    .subtitle {
      margin-top: 6px;
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 100%;
    }

    .pre-sale {
      margin-top: 64px;
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
      width: 488px;
    }

    .pre-sale-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 488px;
      margin-top: 8px;
      text-align: left;
    }

    .pre-sale-image {
      width: 646px;
      height: 434px;
    }

    .urbanization {
      margin-top: 80px;
    }

    .urbanization-image {
      width: 646px;
      height: 434px;
    }

    .urbanization-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
    }

    .urbanization-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 408px;
      margin-top: 8px;
      text-align: left;
    }

    .development {
      margin-top: 80px;
    }

    .development-description {
      color: #282828;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 488px;
      text-align: left;
    }

    .delivery {
      margin-top: 80px;
    }

    .delivery-title {
      width: 488px;
      color: #024E82;
      font-family: Poppins;
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 100px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 110px */
      letter-spacing: -2px;
      text-align: center;
    }

    .subtitle {
      margin-top: 6px;
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
      width: 100%;
    }

    .pre-sale {
      margin-top: 64px;
    }

    .pre-sale-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
    }

    .pre-sale-description {
      color: #282828;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 488px;
      margin-top: 8px;
      text-align: left;
    }

    .pre-sale-image {
      padding-right: 64px;
      width: 616px;
      height: 434px;
    }

    .urbanization {
      margin-top: 80px;
    }

    .urbanization-image {
      padding-left: 64px;
    }

    .urbanization-title {
      color: #024E82;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
    }

    .urbanization-description {
      color: #282828;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 488px;
      margin-top: 8px;
      text-align: left;
    }

    .development {
      margin-top: 80px;
    }

    .development-description {
      color: #282828;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 22px */
      letter-spacing: -0.4px;
      width: 488px;
      text-align: left;
    }

    .delivery {
      margin-top: 80px;
    }

    .delivery-title {
      width: 488px;
      color: #024E82;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 68.2px */
      letter-spacing: -1.24px;
      margin-top: 195px;
      text-align: left;
    }
  }
}