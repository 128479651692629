.ContactForm {
  width: 100%;

  .contact-form-title {
    color: #0E6784;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 52.8px */
    letter-spacing: -0.96px;
  }

  .contact-form-subtitle {
    color: #4C4C4C;
    text-align: center;
    font-family: Inter;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 500;
  }

  label {
    display: block;
    color: #8598AD;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  input {
    background: #FFF;
    strokeWidth: 1px;
    stroke: #D1DCEB;
    filter: drop-shadow(0px 4px 4px rgba(136, 136, 136, 0.13));
    border-radius: 5px;
    padding: 2px;
    border: 1px solid #D1DCEB;
    height: 2rem;
    font-size: 0.7rem;
  }

  .input-div {
    margin-top: 0.5rem;
  }

  .bottom-space {
    margin-top: 100px;
  }

  @media (min-width: 1024px)  {
    .contact-form-title {
      color: #0E6784;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 52.8px */
      letter-spacing: -0.96px;
    }
  
    .contact-form-subtitle {
      color: #4C4C4C;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 26.4px */
      letter-spacing: -0.4px;
    }

    label {
      display: block;
      color: #8598AD;
      font-family: Poppins;
      font-size: 15.594px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  
    input {
      background: #FFF;
      strokeWidth: 1px;
      stroke: #D1DCEB;
      filter: drop-shadow(0px 4px 4px rgba(136, 136, 136, 0.13));
      border-radius: 5px;
      padding: 4px;
      border: 1px solid #D1DCEB;
      height: 47px;
    }

    .input-div {
      margin-top: 1.5rem;
    }

    .bottom-space {
      margin-top: 140px;
    }
  }
}