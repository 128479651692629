.svg-text {
    position:absolute;
    /* background-color:white; */
    width: 100%;
    text-align: center;
    color: #1E5F6B;
    top: 20%;

    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 115%; /* 23px */

  }
  

.svg-content {
    width:145px;
    height:145px;
    position:relative;
    /* background-color:green; */
}

.svg-img {
    /* background-color:red; */
    width:145px;
    height:145px;
    position:absolute;
    display:block;
    left:50%;
    margin-left:-72px;
    margin-top:-73px;
    top:50%;
}