@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir Black';
  src: url('../public/fonts/Avenir\ Black.ttf') format("woff2");
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error-message {
  font-size: small;
  color: red;
  padding-top: 0.2rem;
}