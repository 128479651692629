.Map {
  width: 100%;
  height: 266px;
  background: rgba(210, 227, 236, 0.48);
  z-index: 0;

  .title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 26.4px */
    letter-spacing: -0.48px;
    text-align: center;
    padding-top: 22px;
  }

  .map {
    width: 316px;
    height: 153px;
  }

  .description {
    color: #024E82;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 11px */
    letter-spacing: -0.2px;
    text-align: center;
    margin-top: 9px;
  }

  .controls {
    position: absolute;
    margin-top: 10px;
    margin-right: -280px;
  }

  .zoom-in {
    width: 10px;
    height: 10px;
  }

  .zoom-out {
    margin-top: 5px;
    width: 10px;
    height: 10px;
  }

  @media (min-width: 1024px) {
    height: 814px;
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 41.8px */
      letter-spacing: -0.76px;
      text-align: center;
      padding-top: 53px;
    }

    .map {
      width: 1002px;
      height: 546px;
    }

    .description {
      color: #024E82;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      margin-top: 20px;
      text-align: center;
    }

    .controls {
      position: absolute;
      margin-top: 430px;
      margin-right: -850px;
    }

    .zoom-in {
      width: 36px;
      height: 36px;
    }
  
    .zoom-out {
      margin-top: 13px;
      width: 36px;
      height: 36px;
    }

    .map-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    .map-layer-1 {
        transform: translateZ(-1px);
    }
    
    .map-layer-2 {
        transform: translateZ(-2px);
    }
  }

  @media (min-width: 1366px) {
    height: 814px;
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 41.8px */
      letter-spacing: -0.76px;
      text-align: center;
      padding-top: 53px;
    }

    .map {
      width: 1002px;
      height: 546px;
    }

    .description {
      color: #024E82;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      margin-top: 20px;
      text-align: center;
    }

    .controls {
      position: absolute;
      margin-top: 420px;
      margin-right: -900px;
    }

    .zoom-in {
      width: 36px;
      height: 36px;
    }
  
    .zoom-out {
      margin-top: 13px;
      width: 36px;
      height: 36px;
    }

    .map-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    
    .map-layer-1 {
        transform: translateZ(-1px);
    }
    
    .map-layer-2 {
        transform: translateZ(-2px);
    }
  }

  @media (min-width: 1440px) {
    .title {
      color: #024E82;
      font-family: Poppins;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 41.8px */
      letter-spacing: -0.76px;
      text-align: center;
      padding-top: 53px;
    }

    .map {
      width: 1202px;
      height: 582px;
    }

    .description {
      color: #024E82;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      margin-top: 20px;
      text-align: center;
    }

    .controls {
      position: absolute;
      margin-top: 460px;
      margin-right: -1100px;
    }

    .zoom-in {
      width: 36px;
      height: 36px;
    }
  
    .zoom-out {
      margin-top: 13px;
      width: 36px;
      height: 36px;
    }
  }
}