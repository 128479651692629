.title-hero {
    color: #1E1E1E;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.title-name {
    color: #8598AD;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.header-text {
    color: #203459;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.title-container {
    height: 150px;
}

.background-hero {
    background-color: #EEEEEE;
}