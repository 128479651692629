.Modal {
  margin: 0;
	padding: 0rem;
	font-family: sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background:rgba(40, 40, 40, 0.75);
  color: #000;
  overflow: hidden;

  .close-modal {
    width: auto;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 1rem;
    color: #000000;
  }

  .modal {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 100vh;
    width: 100vw;
    transition: all .5s;
    overflow: hidden;
  }

  .modal-content {
    width: 80%;
    height: auto;
    max-height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 0rem;
    border-radius: 17px;
    background-color: white;
    overflow-y: hidden;
  }

  .modal-content-md {
    width: 60%;
    height: auto;
    max-height: 70%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 0rem;
    border-radius: 17px;
    background-color: white;
    overflow-y: hidden;
  }

  .modal-content-sm {
    width: 35%;
    height: auto;
    max-height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    padding: 0rem;
    border-radius: 17px;
    background-color: white;
    overflow-y: hidden;
  }

  .modal-header {
    background: linear-gradient(90deg, rgba(5, 140, 191, 0.32) 0%, rgba(65, 207, 227, 0.32) 100.01%);
    /* height: 50px; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-title {
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .modal-children-sm {
    overflow-y: auto;
    height: 75%;
    margin-bottom: 0.5rem;
  }

  .modal-children-md {
    overflow-y: auto;
    height: 82%;
    margin-bottom: 0.5rem;
  }

  .modal-children {
    overflow-y: auto;
    height: 80%;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 1024px) {
    .close-modal {
      width: auto;
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      color: #000000;
    }

    .modal {
      position: fixed;
      top: -100vh;
      left: 0;
      height: 100vh;
      width: 100vw;
      transition: all .5s;
      overflow: hidden;
      
    }
  
    .modal-content {
      width: 80%;
      height: auto;
      max-height: 80%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-content-md {
      width: 60%;
      height: auto;
      max-height: 70%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-content-sm {
      width: 35%;
      height: auto;
      max-height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-header {
      background: linear-gradient(90deg, rgba(5, 140, 191, 0.32) 0%, rgba(65, 207, 227, 0.32) 100.01%);
      /* height: 70px; */
      padding-top: 22px;
      padding-bottom: 22px;
      padding-left: 33px;
      padding-right: 20px;
    }
  
    .modal-title {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  
    .modal-children-sm {
      overflow-y: auto;
      height: 75%;
      margin-bottom: 0.5rem;
    }
  
    .modal-children-md {
      overflow-y: auto;
      height: 82%;
      margin-bottom: 0.5rem;
    }
  
    .modal-children {
      overflow-y: auto;
      height: 80%;
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 1440px) {
    .close-modal {
      width: auto;
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      color: #000000;
    }

    .modal {
      position: fixed;
      top: -100vh;
      left: 0;
      height: 100vh;
      width: 100vw;
      transition: all .5s;
      overflow: hidden;
      
    }
  
    .modal-content {
      width: 80%;
      height: auto;
      max-height: 80%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-content-md {
      width: 60%;
      height: auto;
      max-height: 70%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-content-sm {
      width: 35%;
      height: auto;
      max-height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      padding: 0rem;
      border-radius: 17px;
      /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -12.06%, #F5F6FA 92.29%); */
      background-color: white;
      overflow-y: hidden;
    }
  
    .modal-header {
      background: linear-gradient(90deg, rgba(5, 140, 191, 0.32) 0%, rgba(65, 207, 227, 0.32) 100.01%);
      height: 70px;
      padding-top: 22px;
      padding-left: 33px;
      padding-right: 20px;
    }
  
    .modal-title {
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  
    .modal-children-sm {
      overflow-y: auto;
      height: 75%;
      margin-bottom: 0.5rem;
    }
  
    .modal-children-md {
      overflow-y: auto;
      height: 82%;
      margin-bottom: 0.5rem;
    }
  
    .modal-children {
      overflow-y: auto;
      height: 80%;
      margin-bottom: 0.5rem;
    }
  }

}