.label-message {
    color: #8598AD;
    font-family: 'Poppins';
    font-size: 15.594px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.input-message {
    fill: #FFF;
    strokeWidth: 1px;
    stroke: var(--Box, rgba(255, 255, 255, 0.00));
    filter: drop-shadow(0px 4px 4px rgba(136, 136, 136, 0.13));
    border: 1px solid;
}