.FuerzaMigrante {
  width: 100%;
  background: linear-gradient(0deg, rgba(200, 226, 236, 0.00) 38.17%, rgba(23, 136, 149, 0.49) 138.96%);

  .title {
    color: #0E6784;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%; /* 56.16px */
  }

  @media (min-width: 768px) {
    .title {
      color: #0E6784;
      text-align: center;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 56.16px */
    } 
  }

  @media (min-width: 1024px) {
    .title {
      color: #0E6784;
      text-align: center;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 56.16px */
    } 
  }

  @media (min-width: 1440px) {
    .title {
      color: #0E6784;
      text-align: center;
      font-family: Poppins;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: 117%; /* 56.16px */
    } 
  }
}