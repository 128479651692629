.title {
    color: #000;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.2px;
}

.title-white {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.05em;
    text-align: left;

    color: #FFF;
    position: absolute;
    top: 20%;
    left: 25%;
    transform: translateX(-50%);
}