.label-input {
    color: #5A5A5A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.update-password {
    color: #058CBF;

    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.label-pass {
    color: #8598AD;
    font-family: 'Poppins';
    font-size: 15.594px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.input-form {
    stroke: var(--Box, rgba(255, 255, 255, 0.00));
    filter: drop-shadow(0px 4px 4px rgba(136, 136, 136, 0.13));
}

.divider {
    height: 4px;
    background: linear-gradient(90deg, rgba(63, 205, 226, 0.18) 0%, rgba(238, 253, 255, 0.18) 101.96%);;
}

.payment-method-header {
    background: #F8F9FB;
    background-image: drop-shadow(0px 4px 4px rgba(72, 72, 72, 0.25));
}