.RegisterList {
  width: 100%;

  .card-info {
    background-color: #f8f9fb;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px 20px;
  }

  .field-title {
    color: #4c4c4c;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .field-value {
    color: #4c4c4c;
    text-align: right;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .container {
    width: 100%;
    padding: 30px;
    padding-right: 50px;
  }
  .shadow {
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  }
  .text-black-50 {
    color: #4c4c4c;
  }
  tr:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  .hover-links:hover {
    color: #0ea2f8;
    cursor: pointer;
    text-decoration: underline;
  }
}
