.Shopnow{
    width: 100%;
    background-color: #E9EDF0;
    margin: 0px;
    padding: 0px;

    .title{
        font-family: Poppins;
        font-size: 28px;
        font-weight: 500;
        line-height: 42.8px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #0E6784;
    }
    .description{
      color: #282828;
      text-align: center;
      font-family: 'Avenir Black';
      font-size: 18px;
      font-style: normal;
      font-weight: 800;
      line-height: 117%; /* 21.06px */
      letter-spacing: -0.36px;

      b {
        background: linear-gradient(90deg, #058CBF 0%, #41CFE3 100%);
        -webkit-text-fill-color: transparent; 
        -webkit-background-clip: text;
        font-weight: 900;
      }
    }
    .desc-ejemplo{
      font-family: Poppins;
      font-size: 30px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: -0.02em;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #0E6784;
      text-align: center;
    }
    .desc-ejemplo span{
      margin: auto;
      display: block;
      width: fit-content;
    }
    .card-item {
        width: 200px;
        flex-shrink: 0;
        margin-top: 28px;
        margin-left: auto;
        margin-right: auto;
      }
    
      .image-item {
        max-width: 90px;
        height: 100px;
        flex-shrink: 0;
        margin:auto;
      }
    
      .item-title {
        font-size: 22px;
        font-weight: 600;
        line-height: 30.2px;
        letter-spacing: -0.02em;
        text-align: center;
        margin-top: 1rem;
        color: #282828;
      }
      .u-line{
        border-bottom: 3px solid;
        border-color: #0E6784;
      }
    .heading-image{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #0E6784;
    }

    @media (min-width: 768px){
      .title{
        font-family: Poppins;
        font-size: 38px;
        font-weight: 500;
        line-height: 42.8px;
        letter-spacing: -0.02em;
        text-align: center;
        color: #0E6784;
      }
      .description{
        color: #282828;
        text-align: center;
        font-family: 'Avenir Black';
        font-size: 30px;
        font-style: normal;
        font-weight: 800;
        line-height: 117%; /* 21.06px */
        letter-spacing: -0.36px;
      }
    }
    
    @media (min-width: 1024px){
        .title{
        font-family: Poppins;
        font-size: 38px;
        font-weight: 500;
        line-height: 42.8px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #0E6784;
      }
      .description{
        color: #282828;
        text-align: left;
        font-family: 'Avenir Black';
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 117%; /* 21.06px */
        letter-spacing: -0.36px;
      }
      .desc-ejemplo{
        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        line-height: 33px;
        letter-spacing: -0.02em;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0E6784;
        text-align: start;
      }
      .desc-ejemplo span{
        margin: 0;
        display: block;
        width: fit-content;
      }
    }

    @media (min-width: 1440px){
        .title{
            font-family: Poppins;
            font-size: 52px;
            font-weight: 500;
            line-height: 52.8px;
            letter-spacing: -0.02em;
            text-align: left;
        }
        .subtitle{
            color: #0E6784;
            font-family: Poppins;
            font-size: 32px;
            font-weight: 500;
            line-height: 35.2px;
            letter-spacing: -0.02em;
            text-align: center;

        }
        .description{

            font-size: 26px;
            font-weight: 700;
            line-height: 30.42px;
            letter-spacing: -0.02em;
            text-align: left;
        }
        .card-item {
            width: 270px;
            flex-shrink: 0;
            margin-top: 28px;
            margin-left: auto;
            margin-right: auto;
          }
        
          .image-item {
            max-width: 120px;
            height: 100px;
            flex-shrink: 0;
            margin:auto;
          }
        
          .item-title {
            
            font-size: 22px;
            font-weight: 600;
            line-height: 30.2px;
            letter-spacing: -0.02em;
            text-align: center;
            margin-top: 1rem;
            color: #282828;
          }
          .u-line{
            border-bottom: 3px solid;
            border-color: #0E6784;
          }
        .heading-image{
            font-family: Poppins;
            font-size: 32px;
            font-weight: 500;
            line-height: 35.2px;
            letter-spacing: -0.02em;
            text-align: center;
            color: #0E6784;
        }
    }
}