.About {
  width: 100%;
  /*background-color: #E9EDF0;*/

  .title {
    color: #E8ECEF;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 24.2px */
    letter-spacing: -0.44px;
  }

  .subtitle {
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 13.2px */
    letter-spacing: -0.2px;
    margin-top: 12px;
    width: 266px;
  }

  .carousel-section {
    margin-top: 28px;
    margin-bottom: 0px;
  }

  .item {
    width: 477px;
    height: 345px;
    flex-shrink: 0;
  }

  .item-img {
    width: 477px;
    height: 345px;
    flex-shrink: 0;
    object-fit: cover;
  }

  .back-gradient{
    background-image: url('../../../../../assets/home/images/about/back.png');
    background-size: cover;

  }
  .txt-white{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    text-align: center;
    color: #fff;
  }
  .txt-blue{
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    text-align: center;
    color: #024E5B;
  }
  ul.c-list{
    list-style-image:url('../../../../../assets/home/images/about/bullet.svg') ;
  }
  .item-list{
    height: 50px;
  }
  .item-list .item-text{
    color:#024E5B;
    font-family: 'Avenir Black';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 128%; /* 25.6px */
    letter-spacing: -0.4px;

  }

  @media (min-width: 768px) {
    .title {
      color: #E8ECEF;
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 24.2px */
      letter-spacing: -0.44px;
    }
  
    .subtitle {
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 13.2px */
      letter-spacing: -0.2px;
      margin-top: 12px;
      width: 266px;
    }
  
    .carousel-section {
      margin-top: 28px;
      margin-bottom: 0px;
    }
  
    .item {
      width: 477px;
      height: 345px;
      flex-shrink: 0;
    }
  
    .item-img {
      width: 477px;
      height: 345px;
      flex-shrink: 0;
      object-fit: cover;
    }
  
    .back-gradient{
      background-image: url('../../../../../assets/home/images/about/back.png');
      background-size: cover;
  
    }
    .txt-white{
      font-family: Poppins;
      font-size: 25px;
      font-weight: 500;
      line-height: 110%;
      text-align: center;
      color: #fff;
    }
    .txt-blue{
      font-family: Poppins;
      font-size: 25px;
      font-weight: 500;
      line-height: 110%;
      text-align: center;
      color: #024E5B;
    }
    ul.c-list{
      list-style-image:url('../../../../../assets/home/images/about/bullet.svg') ;
    }
    .item-list{
      height: 50px;
    }
    .item-list .item-text{
      color: #024E5B;
      font-family: 'Avenir Black';
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
      line-height: 128%; /* 25.6px */
      letter-spacing: -0.4px;
  
    }
  }

  @media (min-width: 1024px) {
    .title {
      color: #E8ECEF;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 110%; /* 39.6px */
      text-align: left;
    }

    .subtitle {
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      width: 772px;
    }

    .carousel-section {
      margin-top: 64px;
      margin-bottom: 0px;
    }
    .txt-white{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 110%;
      text-align: left;
      color: #fff;
    }
    .txt-blue{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 110%;
      text-align: center;
      color: #024E5B;
    }
    ul.c-list{
      list-style-image:url('../../../../../assets/home/images/about/bullet.svg') ;
    }
    .item-list{
      height: 40px;
    }
    .item-list .item-text{
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 128%;
      letter-spacing: -0.02em;
      text-align: left;
      color: #024E5B;
      padding-left: 0rem;
  
    }

    .txt-white{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 110%;
      text-align: left;
      color: #fff;
    }
    .txt-blue{
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 110%;
      text-align: center;
      color: #024E5B;
    }
  }

  @media (min-width: 1366px) {
    .title {
        color: #E8ECEF;
        text-align: left;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 24.2px */
    }

    .subtitle {
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      margin-top: 18px;
      width: 772px;
    }

    .carousel-section {
      margin-top: 64px;
      margin-bottom: 0px;
    }

    .txt-white{
      font-family: Poppins;
      font-size: 32px;
      font-weight: 500;
      line-height: 110%;
      text-align: left;
      color: #fff;
    }
    .txt-blue{
      font-family: Poppins;
      font-size: 32px;
      font-weight: 500;
      line-height: 110%;
      text-align: start;
      color: #024E5B;
    }

    .item-list{
      height: 70px;
    }

    .item-list .item-text{
      font-family: Poppins;
      font-size: 26px;
      font-weight: 400;
      line-height: 128%;
      letter-spacing: -0.02em;
      text-align: left;
      color: #024E5B;
      padding-left: 0rem;
  
    }
  }

  @media (min-width: 1440px) {
    .title {
        color: #E8ECEF;
        text-align: left;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%; /* 24.2px */
    }

    .subtitle {
      color: #282828;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 132%; /* 21.12px */
      letter-spacing: -0.32px;
      margin-top: 18px;
      width: 772px;
    }

    .carousel-section {
      margin-top: 64px;
      margin-bottom: 0px;
    }

    .txt-white{
      font-family: Poppins;
      font-size: 32px;
      font-weight: 500;
      line-height: 110%;
      text-align: left;
      color: #fff;
    }
    .txt-blue{
      font-family: Poppins;
      font-size: 32px;
      font-weight: 500;
      line-height: 110%;
      text-align: start;
      color: #024E5B;
    }
    .item-list{
      height: 70px;
    }

    .item-list .item-text{
      font-family: Poppins;
      font-size: 32px;
      font-weight: 400;
      line-height: 128%;
      letter-spacing: -0.02em;
      text-align: left;
      color: #024E5B;
      padding-left: 0rem;
  
    }
  }
}