.Contact {
  width: 100%;
  background-color: #E9EDF0;
  background-image: url('../../../../../assets/home/images/contact/gradientcont.png');
  background-position: bottom;
  background-size: cover;

  .space-form {
    padding-top: 40px;
  }

  .title {
    color: #024E82;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2rem; /* 26.4px */
  }

  .carousel-img {
    width: 10rem;
    height: auto;
    flex-shrink: 0;
  }

  .carousel-description {
    width: 10rem;
    color: #0D5C6B;
    text-align: center;
    font-family: Inter;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 26.4px */
    letter-spacing: -0.44px;
  }

  .avatar-img {
    width: 54px;
    height: 54px;
    border: 3px solid #024E82;
    border-radius: 50px;
  }

  .avatar-title {
    color: #4C4C4C;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .avatar-description {
    color: #4C4C4C;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 14.4px */
  }

  .carousel-dot {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: #C3D2D4;
    cursor: pointer;
  }

  .carousel-dot-active {
    background-color: #024E82;
  }

  @media (min-width: 1024px) {

    .title {
      color: #024E82;
      text-align: center;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 39.6px */
      letter-spacing: -0.72px;
    }

    .carousel-img {
      width: 735px;
      height: 392px;
      flex-shrink: 0;
    }

    .carousel-description {
      width: 598px;
      color: #0D5C6B;
      text-align: center;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 26.4px */
      letter-spacing: -0.44px;
    }
  }

}