.Slider {
  .image-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .splide__arrows {
    display: none;
  }

  .slide-title {
    color: #F8F8F8;
    font-family: "Right Grotesk";
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%; /* 30px */
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }
}