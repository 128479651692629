.Loading {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent white background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Ensure it's above other elements on the page */
  }
  
  .loader {
    /* border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%; */
    width: 100px;
    height: 100px;
    /* animation: spin 1s linear infinite; */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}