.NavBar {
  /* height: 61px; */
  /*background: linear-gradient(90deg, rgba(5, 140, 191, 0.32) 0%, rgba(65, 207, 227, 0.32) 100.01%);*/
  padding-right: 35px;
  padding-top: 9px;
  padding-bottom: 9px;

  .avatar {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    background-color: #F8F8F9;
    border-radius: 50%;
    display: grid; 
    place-items: center;
  }

  .submenu {
    position: absolute;
    z-index: 50;
    top: 55px;
    right: 5px;
    fill: #F8F8F9;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
  }
  .search {
    display: none !important;
  }

  @media (min-width: 1024px) {
    padding-right: 46px;
    padding-top: 9px;
    padding-bottom: 9px;

    .avatar {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      background-color: #F8F8F9;
      border-radius: 50%;
      display: grid; 
      place-items: center;
    }
  
    .submenu {
      position: absolute;
      z-index: 50;
      top: 60px;
      right: 10px;
      fill: #F8F8F9;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
    }
  }

  @media (min-width: 1440px) {
    padding-right: 46px;
    padding-top: 9px;
    padding-bottom: 9px;

    .avatar {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      background-color: #F8F8F9;
      border-radius: 50%;
      display: grid; 
      place-items: center;
    }
  
    .submenu {
      position: absolute;
      z-index: 50;
      top: 60px;
      right: 10px;
      fill: #F8F8F9;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.07));
    }
  }
}