.Navbar {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgba(181, 226, 243, 0.42) 0%, rgba(65, 207, 227, 0.42) 100.01%);
  padding: 10px 30px;
  
  .logo {
    width: 70px;
    margin-left: 0rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 15px 10px;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  li {
    float: left;
  }
  
  li a {
    display: inline;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .mobile-menu {
    background: linear-gradient(90deg, rgba(181, 226, 243, 0.42) 0%, rgba(65, 207, 227, 0.42) 100.01%);
  }

  .avatar-logo {
    width: 34px;
    height: 34px;
    /* float: left; */
  }

  @media (min-width: 1024px) {
    width: 100%;
    height: 61px;
    padding: 10px 50px;
    height: 65px;

    .logo {
      width: 50px;
    }

    li {
      float: left;
    }
    
    li a {
      display: block;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      color: #FFF;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .avatar-logo {
      width: 44px;
      height: 44px;
    }
  }

  @media (min-width: 1440px) {
    width: 100%;
    height: 61px;
    padding: 10px 62px;

    .logo {
      width: 42px;
    }

    li {
      float: left;
    }
    
    li a {
      display: block;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      color: #FFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }

    .avatar-logo {
      width: 44px;
      height: 44px;
    }
  }
  
}