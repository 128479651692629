.progress-button {
  display: block !important;
}

.show-button {
  display: none !important;
}

thead {
  height: 0px !important;
}

tbody tr td {
align-content: flex-start;
}

@media (min-width: 1220px) {
  .progress-button {
    display: none !important;
  }

  .show-button {
    display: block !important;
  }
}
