.primary-btn-text {
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  
  .primary-btn-size {
    max-width: 187.34px;
    width: 187.34px;
    height: 28px;
    max-height: 30px;
  }